import "./_index.scss";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import LoadingImage from "components/LoadingImage";
import config from "utils/config";

const ForgetPassword = (props) => {
  const { formik, submitError, setSubmitError, handleSwitchToLogin } = props;

  return (
    <>
      <div className="auth-header">
        <img
          src={require("assets/login_illustration.svg").default}
          alt="logo"
          className="auth-header-illustration"
          style={{
            height: config.auth_popup.login_modal.header.illustrationHeight,
          }}
        />
        <div className="auth-header-title">
          {config.auth_popup.login_modal.header.title}
        </div>
        <p className="auth-header-subtitle">
          {config.auth_popup.forget_password.header_subtitle}
        </p>
        <div className="auth-header-content"></div>
      </div>
      <div className="auth-form-container">
        <div
          style={{
            height: "fit-content",
          }}
          className="auth-form"
        >
          <div className="auth-form-forgot-password">
            <Grid container>
              <Grid item xs={12}>
                <p className="auth-form-forgot-password-title">
                  {config.auth_popup.forget_password.title}
                </p>
              </Grid>
              <Grid item xs={12}>
                <p className="auth-form-forgot-password-subtitle">
                  {config.auth_popup.forget_password.subtitle}
                </p>
              </Grid>
              <Grid item xs={12} style={{ margin: "24px 0" }}>
                <TextField
                  fullWidth
                  id="email"
                  name="email"
                  label="Email address"
                  InputProps={{ sx: { borderRadius: "8px" } }}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                {formik.isSubmitting ? (
                  <LoadingImage></LoadingImage>
                ) : (
                  <FormControl fullWidth>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setSubmitError("");
                        formik.handleSubmit();
                      }}
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      className="auth-form-confirm-btn hover:!bg-blue-dark"
                      variant="contained"
                    >
                      Send Recovery Email
                    </Button>
                    <FormHelperText>{submitError}</FormHelperText>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                <p className="auth-form-forgot-password-go-to-signup">
                  Just remember?{" "}
                  <Link onClick={handleSwitchToLogin}>Sign In</Link>
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
