import "./_index.scss";
import { Button, FormControl, FormHelperText, Grid } from "@mui/material";
import LoadingImage from "components/LoadingImage";
import config from "utils/config";

const CongratulateAfterSignup = (props) => {
  const { formik, submitError, setSubmitError } = props;

  return (
    <div className="auth-form-congratulate">
      <img
        src={require("assets/auth_congratulate.gif")}
        alt="logo"
        className="auth-form-congratulate-illustration"
      />
      <Grid container>
        <Grid item xs={12}>
          <p className="auth-form-congratulate-title">
            {config.auth_popup.congratulate.title}
          </p>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "6px", marginBottom: "23px" }}>
          <p className="auth-form-congratulate-subtitle">
            {config.auth_popup.congratulate.subtitle}
          </p>
        </Grid>
        <Grid item xs={12} style={{display: "flex"}}>
          {formik.isSubmitting ? (
            <LoadingImage></LoadingImage>
          ) : (
            <FormControl fullWidth>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setSubmitError("");
                  formik.handleSubmit();
                }}
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                className="auth-form-confirm-btn hover:!bg-blue-dark"
                variant="contained"
              >
                Let's Go
              </Button>
              <FormHelperText>
                {submitError}
              </FormHelperText>
            </FormControl>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default CongratulateAfterSignup;
