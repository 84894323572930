import { useQuery } from "@tanstack/react-query";
import "./_index.scss";
import { APIGetSubscriptionPlans } from "utils/api";
import LoadingImage from "components/LoadingImage";
import ErrorPage from "views/ErrorPage";
import { useState } from "react";

const PricingCard: any = ({
  price,
  discountedPrice,
  name,
  description,
  features,
  popularBadge,
}) => {
  console.log(discountedPrice);
  return (
    <div
      className={`flex flex-col ${
        popularBadge
          ? "pt-5 pb-11 w-full rounded-3xl shadow-[0px_33px_38.5px_-7px_rgba(139,139,139,0.08)] bg-[linear-gradient(156deg,#5DA5FA_2.21%,#255CA0_101.02%)]"
          : "grow px-[33px] pt-12 pb-8 mx-auto mt-12 w-full font-light bg-white rounded-3xl shadow-[0px_7px_29px_0px_rgba(100,100,111,0.20)] max-md:mt-10"
      }`}
    >
      {popularBadge && (
        <div className="flex justify-center items-center self-end w-[121px] h-[27px] mr-5 custom-text-poppins-500-10px text-center text-blue bg-white rounded-xl max-md:mr-2.5">
          MOST POPULAR
        </div>
      )}
      <div
        className={`flex flex-col ${
          popularBadge ? "px-6 mt-3 text-white max-md:pl-5" : ""
        }`}
      >
        <div className="flex gap-2 whitespace-nowrap">
          {discountedPrice && (
            <div
              className={`custom-text-poppins-400-25px-1_84 leading-10 text-[#D1D5DB] line-through mt-1`}
            >
              €{discountedPrice}
            </div>
          )}
          <div
            className={`text-4xl custom-text-poppins-600-36px-1_27 leading-10 ${
              popularBadge ? "" : "text-gray-900"
            }`}
          >
            €{price}
          </div>
          <div
            className={`self-start mt-3 custom-text-poppins-500-17px ${
              popularBadge ? "font-medium" : "text-[#848199]"
            }`}
          >
            /month
          </div>
        </div>
        <div
          className={`mt-9 text-3xl ${
            popularBadge
              ? "custom-text-poppins-800-28px"
              : "custom-text-poppins-600-28px text-blue"
          }`}
        >
          {name}
        </div>
        <div
          className={`mt-5 mb-[15px] custom-text-poppins-500-14px ${
            popularBadge ? "" : "text-[#848199] max-md:ml-2"
          }`}
        >
          {description}
        </div>
        {features.map((feature, index) => (
          <div key={index} className={`flex gap-2.5 mt-[11px]`}>
            {popularBadge ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  opacity="0.1"
                  d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                  fill="white"
                />
                <path
                  d="M15.7722 6.83362L10.068 14.5745C9.93197 14.7549 9.72912 14.8732 9.50503 14.9027C9.28094 14.9321 9.05441 14.8703 8.87634 14.7311L4.80301 11.4745C4.44356 11.1868 4.38536 10.6622 4.67301 10.3028C4.96066 9.94334 5.48523 9.88514 5.84468 10.1728L9.24134 12.8903L14.4305 5.84778C14.6007 5.59244 14.8974 5.45127 15.2029 5.48032C15.5083 5.50936 15.7731 5.70393 15.8921 5.98676C16.0111 6.2696 15.965 6.59494 15.7722 6.83362Z"
                  fill="white"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C19.9936 4.47982 15.5202 0.00642897 10 0Z"
                  fill="#EAF3FF"
                />
                <path
                  d="M15.7722 6.83362L10.068 14.5745C9.93197 14.7549 9.72912 14.8732 9.50503 14.9027C9.28094 14.9321 9.05441 14.8703 8.87634 14.7311L4.80301 11.4745C4.44356 11.1868 4.38536 10.6622 4.67301 10.3028C4.96066 9.94334 5.48523 9.88514 5.84468 10.1728L9.24134 12.8903L14.4305 5.84778C14.6007 5.59244 14.8974 5.45127 15.2029 5.48032C15.5083 5.50936 15.7731 5.70393 15.8921 5.98676C16.0111 6.2696 15.965 6.59494 15.7722 6.83362Z"
                  fill="#407EC9"
                />
              </svg>
            )}
            <div className={`flex-auto my-auto custom-text-poppins-300-14px`}>
              {feature}
            </div>
          </div>
        ))}
        <button
          className={`justify-center self-center w-[186px] h-[43px] mt-[35px] custom-text-poppins-500-15px text-center rounded-[18px] max-md:px-5 ${
            popularBadge
              ? "text-blue bg-white"
              : "text-white bg-[linear-gradient(180deg,#245A9E_0%,#5EA6FC_100%)]"
          }`}
        >
          Try for free
        </button>
      </div>
    </div>
  );
};

const PricingPage = () => {
  const [showYearly, setShowYearly] = useState(false);
  const [loading, setLoading] = useState(false);
  const query = useQuery({
    queryKey: ["subscription-plans"],
    queryFn: APIGetSubscriptionPlans,
  });
  if (query.isSuccess) {
    query.data.data.sort((a, b) => a.order - b.order);
  }

  /**
   * Set a fake loading
   */
  const setDisplayPrice = (yearly) => {
    setShowYearly(yearly);
    setLoading(true);
    setTimeout(() => setLoading(false), 500);
  };

  const getPlanPrice = (plan, yearly) => {
    if (yearly) {
      return {
        price: plan.final_price_yearly,
        discounted_plan: plan.price_yearly === 0 ? null : plan.price_yearly,
      };
    }
    return {
      price: plan.final_price_monthly,
      discounted_plan: plan.price_monthly === 0 ? null : plan.price_monthly,
    };
  };

  return (
    <div className="mt-[30px] flex justify-center items-center mb-[155px] bg-white max-md:px-5 ">
      <div className="flex flex-col items-center mt-7 w-full max-md:max-w-full">
        <div className="custom-text-poppins-600-20px text-center text-gray-500">
          Check our
        </div>
        <h2 className="custom-text-volkhov-700-40px text-center text-gray-900 max-md:max-w-full">
          Simple & transparent pricing
        </h2>
        <div className="mt-[15px] custom-text-poppins-400-20px text-center text-slate-500 max-md:max-w-full">
          <span className="custom-text-poppins-600-20px">
            14 days Free Trial{" "}
          </span>
          included & No surprise fees.
        </div>
        {(query.isLoading || loading) && <LoadingImage className="!my-20" />}
        {!loading && (
          <>
            {query.isError && <ErrorPage />}
            {query.isSuccess && (
              <>
                <div className="flex mt-12 text-center max-md:mt-10">
                  <div className="flex whitespace-nowrap">
                    <button
                      onClick={() => {
                        setDisplayPrice(false);
                      }}
                      className={`w-[111px] h-[44px] ${
                        showYearly
                          ? "bg-white hover:bg-[rgb(231,231,231)] text-[#848199] shadow-[0px_7px_31.3px_0px_rgba(100,100,111,0.09)]"
                          : "bg-blue hover:bg-blue-dark text-white shadow-[0px_5px_16.6px_0px_rgba(64,126,201,0.30)]"
                      } custom-text-poppins-600-10px rounded-[22px]  transition-colors duration-300 ease-in-out z-10`}
                    >
                      MONTHLY
                    </button>
                    <div className="w-[92px] h-[44px]">
                      <button
                        onClick={() => {
                          setDisplayPrice(true);
                        }}
                        className={`w-[132px] h-[44px] pr-[28.5px] -translate-x-[40px] ${
                          showYearly
                            ? "bg-blue hover:bg-blue-dark text-white shadow-[0px_5px_16.6px_0px_rgba(64,126,201,0.30)]"
                            : "bg-white hover:bg-[rgb(231,231,231)] text-[#848199] shadow-[0px_7px_31.3px_0px_rgba(100,100,111,0.09)]"
                        } custom-text-poppins-600-10px text-right rounded-[22px] transition-colors duration-300 ease-in-out`}
                      >
                        YEARLY
                      </button>
                    </div>
                  </div>
                  <div className="w-[5px] translate-x-[2px]">
                    <div className="w-[33px] h-[34px]">
                      <img
                        alt="left-arrow"
                        className="-translate-x-[28.5px] -translate-y-[8px]"
                        src={
                          require("assets/akar-icons_arrow-back.svg").default
                        }
                      />
                    </div>
                  </div>
                  <div className="w-[79px] h-[28px] my-auto content-center justify-center custom-text-poppins-700-9_5px text-blue bg-transparent rounded-[14px] border border-blue border-solid">
                    Save -20%
                  </div>
                </div>
                <section className="self-stretch mx-auto mt-16 max-md:mt-10 max-md:max-w-full">
                  <div className="flex gap-[13px] max-md:flex-col max-md:gap-0">
                    {query.data.data.map((plan, index) => (
                      <div
                        key={index}
                        className={`flex flex-col ${
                          showYearly ? "w-[350px]" : "w-[281px]"
                        } max-md:ml-0 max-md:w-full`}
                      >
                        <PricingCard
                          {...plan}
                          popularBadge={plan.is_recommended}
                          discountedPrice={
                            getPlanPrice(plan, showYearly).discounted_plan
                          }
                          price={getPlanPrice(plan, showYearly).price}
                        />
                      </div>
                    ))}
                    {/* show no items found error */}
                    {query.data.data.length === 0 && (
                      <div className="flex flex-col w-[281px] max-md:ml-0 max-md:w-full">
                        No items found.
                      </div>
                    )}
                  </div>
                </section>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PricingPage;
