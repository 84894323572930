import "./_index.scss";
import Modal from "@mui/material/Modal";
import { Backdrop, IconButton, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  COGNITO_ERROR_USER_NOT_VERIFIED,
  cognito_forget_password,
  cognito_resend_confirmation_code,
  cognito_reset_password,
  cognito_signin,
  cognito_signup,
  cognito_verify,
} from "utils/cognito";
import {
  APIAuthenticateUserWithToken,
  APICreateUserFeedbackAnswer,
} from "utils/api";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import LoadingImage from "components/LoadingImage";
import VerifyForm from "./VerifyForm";
import CongratulateAfterSignup from "./CongratulateAfterSignup";
import { useAppDispatch } from "store/hooks";
import { authenticate } from "store/slices/auth";
import ForgetPasswordForm from "./ForgetPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";
import { useDebugMode } from "components/DebugContext/DebugContext";
import {
  emailValidationSchema,
  loginValidationSchema,
  signupValidationSchema,
  verifyValidationSchema,
  resetPasswordValidationSchema,
  mapFormikOnboardingValuesToApiAnswerRequestSchema,
} from "utils/utils";
import OnBoardingForm from "./OnBoardingForm";

export enum AUTH_STATUS {
  SIGNUP,
  LOGIN,
  VERIFY,
  CONGRATULATE,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  ONBOARDING,
  TRANSITIONING,
}

const AuthModal = (props) => {
  const { id, open, handleClose, data } = props;
  const dispatch = useAppDispatch();
  const [submitError, setSubmitError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [authStatus, setAuthStatus] = useState(
    data || AUTH_STATUS.SIGNUP
  );
  const { debugMode } = useDebugMode();

  // ONBOARDING
  const [currentOnboarding, setCurrentOnboarding] = useState(0);

  // FORMS
  const login_formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      tos: false,
    },
    validationSchema: loginValidationSchema,
    onSubmit: (values) => {
      cognito_signin(values.email, values.password)
        .then((res: CognitoUserSession) => {
          setSubmitError("");
          setSuccessMessage("");
          return res.getIdToken().getJwtToken();
        })
        .then((token) => {
          APIAuthenticateUserWithToken(token)
            .then((res) => {
              login_formik.setSubmitting(false);
              dispatch(authenticate(true, null));
              handleTransition(AUTH_STATUS.ONBOARDING);
            })
            .catch((err) => {
              setSubmitError("Something went wrong, please try again");
              login_formik.setSubmitting(false);
            });
        })
        .catch(async (err) => {
          if (err.code === COGNITO_ERROR_USER_NOT_VERIFIED) {
            login_formik.setSubmitting(true);
            verify_formik.setFieldValue("email", values.email, false);
            cognito_resend_confirmation_code(values.email)
              .then((res) => {
                handleTransition(AUTH_STATUS.VERIFY);
              })
              .catch((err) => {
                console.log(err);
                setSubmitError(err.message);
                login_formik.setSubmitting(false);
              });
          } else {
            console.log(err);
            setSubmitError(err.message);
            login_formik.setSubmitting(false);
          }
        });
    },
  });
  const signup_formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      given_name: "",
      family_name: "",
      tos: false,
    },
    validationSchema: signupValidationSchema,
    onSubmit: (values) => {
      cognito_signup(
        values.email,
        values.password,
        values.given_name,
        values.family_name
      )
        .then((res) => {
          signup_formik.setSubmitting(false);
          verify_formik.setFieldValue("email", values.email, false);
          handleTransition(AUTH_STATUS.VERIFY);
        })
        .catch((err) => {
          console.log(err);
          setSubmitError(err.message);
          signup_formik.setSubmitting(false);
        });
    },
  });
  const verify_formik = useFormik({
    initialValues: {
      email: "",
      code: "",
    },
    validationSchema: verifyValidationSchema,
    onSubmit: (values) => {
      cognito_verify(values.email, values.code)
        .then((res) => {
          verify_formik.setSubmitting(false);

          login_formik.setFieldValue("email", values.email, false);
          login_formik.setFieldValue(
            "password",
            signup_formik.values.password,
            false
          );
          login_formik.setFieldValue("tos", true, false);
          handleTransition(AUTH_STATUS.CONGRATULATE);
        })
        .catch((err) => {
          console.log(err);
          setSubmitError(err.message);
          verify_formik.setSubmitting(false);
        });
    },
  });
  const forget_password_formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailValidationSchema,
    onSubmit: (values) => {
      cognito_forget_password(values.email)
        .then((res) => {
          forget_password_formik.setSubmitting(false);
          handleSwitchToResetPassword();
        })
        .catch((err) => {
          console.log(err);
          setSubmitError(err.message);
          forget_password_formik.setSubmitting(false);
        });
    },
  });
  const reset_password_formik = useFormik({
    initialValues: {
      email: "",
      code: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordValidationSchema,
    onSubmit: (values) => {
      if (values.email === "") {
        setSubmitError("An error occurred, please try again.");
        return;
      }
      cognito_reset_password(values.email, values.code, values.password)
        .then((res) => {
          reset_password_formik.setSubmitting(false);
          reset_password_formik.resetForm();
          setSuccessMessage("Password reset successfully!");
          handleTransition(AUTH_STATUS.LOGIN);
        })
        .catch((err) => {
          console.log(err);
          setSubmitError(err.message);
          reset_password_formik.setSubmitting(false);
        });
    },
  });
  const onboarding_formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      APICreateUserFeedbackAnswer(
        mapFormikOnboardingValuesToApiAnswerRequestSchema(values)
      )
        .then((res) => {
          setCurrentOnboarding(currentOnboarding + 1);
          onboarding_formik.setSubmitting(false);
        })
        .catch((err) => {
          console.log(err);
          setSubmitError("An error occurred, please try again.");
          onboarding_formik.setSubmitting(false);
          setCurrentOnboarding(currentOnboarding);
        });
    },
  });
  // SWITCH MODALS
  const resetForms = () => {
    // login_formik.resetForm();
    // signup_formik.resetForm();
    setSubmitError("");
  };
  const handleTransition = (targetStatus) => {
    setAuthStatus(AUTH_STATUS.TRANSITIONING);
    setTimeout(() => {
      setAuthStatus(targetStatus);
      resetForms(); // Assuming resetForms needs to be called in every transition
    }, 200);
  };
  const handleSwitchToResetPassword = () => {
    setAuthStatus(AUTH_STATUS.TRANSITIONING);
    reset_password_formik.resetForm();

    setTimeout(() => {
      reset_password_formik.setFieldValue(
        "email",
        forget_password_formik.values.email,
        false
      );
      setAuthStatus(AUTH_STATUS.RESET_PASSWORD);
    }, 200);

    resetForms();
  };

  useEffect(() => {
    if (open) {
      setAuthStatus(data || AUTH_STATUS.SIGNUP);
    }
  
  }, [open, data]);

  return (
    <Modal
      open={open}
      onClose={(e) => {
        handleClose(id);
      }}
      aria-labelledby="Auth Modal"
      aria-describedby="SignIn or SignUp to our platform."
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: "rgba(255,255,255,0.2)",
            backdropFilter: "blur(2px)",
          },
        },
      }}
    >
      <div
        style={{
          outline: "none",
          display: authStatus === AUTH_STATUS.CONGRATULATE ? "flex" : "grid",
        }}
        className="auth-modal"
      >
        {debugMode && (
          <div className="border-dotted border-red border-4 rounded absolute top-0 w-full flex flex-row justify-between cursor-pointer">
            <span className="text-red font-thin text-xs absolute right-2 z-50 mt-6">
              debug mode
            </span>
            <Link onClick={() => setAuthStatus(AUTH_STATUS.LOGIN)}>Login</Link>
            <Link onClick={() => setAuthStatus(AUTH_STATUS.SIGNUP)}>
              Signup
            </Link>
            <Link onClick={() => setAuthStatus(AUTH_STATUS.VERIFY)}>
              Verify
            </Link>
            <Link onClick={() => setAuthStatus(AUTH_STATUS.ONBOARDING)}>
              Onboarding
            </Link>
            <Link onClick={() => setAuthStatus(AUTH_STATUS.CONGRATULATE)}>
              Congratulate
            </Link>
            <Link onClick={() => setAuthStatus(AUTH_STATUS.FORGOT_PASSWORD)}>
              Forgot Password
            </Link>
            <Link onClick={() => setAuthStatus(AUTH_STATUS.RESET_PASSWORD)}>
              Reset Password
            </Link>
          </div>
        )}

        {/* Add x IconButton at the top right, on mobile */}
        <IconButton
          aria-label="Close"
          onClick={() => handleClose(id)}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 100,
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* ---- */}


        {authStatus === AUTH_STATUS.CONGRATULATE && (
          <CongratulateAfterSignup
            formik={login_formik}
            submitError={submitError}
            setSubmitError={setSubmitError}
          />
        )}
        {authStatus === AUTH_STATUS.TRANSITIONING && (
          <>
            <div className="auth-header">
              <LoadingImage></LoadingImage>
            </div>
            <div className="auth-form-container">
              <div
                style={{
                  height: "100%",
                }}
                className="auth-form"
              >
                <LoadingImage />
              </div>
            </div>
          </>
        )}
        {authStatus === AUTH_STATUS.SIGNUP && (
          <>
            <SignupForm
              handleSwitchToLogin={() => handleTransition(AUTH_STATUS.LOGIN)}
              submitError={submitError}
              setSubmitError={setSubmitError}
              formik={signup_formik}
            />
          </>
        )}
        {authStatus === AUTH_STATUS.VERIFY && (
          <VerifyForm
            submitError={submitError}
            setSubmitError={setSubmitError}
            formik={verify_formik}
          />
        )}
        {authStatus === AUTH_STATUS.LOGIN && (
          <>
            <LoginForm
              handleSwitchToSignup={() => handleTransition(AUTH_STATUS.SIGNUP)}
              submitError={submitError}
              setSubmitError={setSubmitError}
              formik={login_formik}
              handleSwitchToForgetPassword={() =>
                handleTransition(AUTH_STATUS.FORGOT_PASSWORD)
              }
              successMessage={successMessage}
            />
          </>
        )}
        {authStatus === AUTH_STATUS.FORGOT_PASSWORD && (
          <ForgetPasswordForm
            submitError={submitError}
            setSubmitError={setSubmitError}
            formik={forget_password_formik}
            handleSwitchToLogin={() => handleTransition(AUTH_STATUS.LOGIN)}
          />
        )}
        {authStatus === AUTH_STATUS.RESET_PASSWORD && (
          <ResetPasswordForm
            submitError={submitError}
            setSubmitError={setSubmitError}
            formik={reset_password_formik}
            handleSwitchToLogin={() => handleTransition(AUTH_STATUS.LOGIN)}
            successMessage={successMessage}
          />
        )}
        {authStatus === AUTH_STATUS.ONBOARDING && (
          <OnBoardingForm
            submitError={submitError}
            setSubmitError={setSubmitError}
            formik={onboarding_formik}
            handleSwitchToLogin={() => handleTransition(AUTH_STATUS.LOGIN)}
            handleSwitchToCongratulate={() =>
              handleTransition(AUTH_STATUS.CONGRATULATE)
            }
            handleClose={() => handleClose(id)}
            successMessage={successMessage}
            currentOnboarding={currentOnboarding}
            setCurrentOnboarding={setCurrentOnboarding}
          />
        )}
      </div>
    </Modal>
  );
};

export default AuthModal;
