import "./_index.scss";
import config from "utils/config";
import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingImage from "components/LoadingImage";
import SSOForm from "../SSOForm";

const LoginForm = (props) => {
  const {
    formik,
    submitError,
    setSubmitError,
    handleSwitchToSignup,
    handleSwitchToForgetPassword,
    successMessage,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <>
      <div className="auth-header">
        <img
          src={require("assets/login_illustration.svg").default}
          alt="logo"
          className="auth-header-illustration"
          style={{
            height: config.auth_popup.login_modal.header.illustrationHeight,
          }}
        />
        <div className="auth-header-title">
          {config.auth_popup.login_modal.header.title}
        </div>
        <p className="auth-header-subtitle">
          {config.auth_popup.login_modal.header.subtitle}
        </p>
      </div>
      <div className="auth-form-container">
        <div
          style={{
            height: "fit-content",
          }}
          className="auth-form"
        >

        <div className="login-form">
          <div className="login-form-title">
            {config.auth_popup.login_modal.form.title}
          </div>
          <p className="login-form-subtitle">
            Don't have an account?{" "}
            <Link onClick={handleSwitchToSignup}>Sign up</Link>
          </p>
          <div className="login-form-email">
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email address"
              InputProps={{ sx: { borderRadius: "8px" } }}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </div>
          <div className="login-form-password">
            <TextField
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                sx: { borderRadius: "8px" },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className="login-form-forgot-password">
            <Link onClick={handleSwitchToForgetPassword}>Forgot password?</Link>
          </div>
          {formik.isSubmitting ? (
            <LoadingImage></LoadingImage>
          ) : (
            <FormControl className="login-form-btn-signin-container">
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setSubmitError("");
                  formik.handleSubmit();
                }}
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                className="login-form-btn-signin hover:!bg-blue-dark"
                variant="contained"
              >
                Sign In
              </Button>
              <FormHelperText
                className="login-form-btn-signin-error"
                style={{ color: "#EF4444" }}
              >
                {submitError}
              </FormHelperText>
              <FormHelperText
                className="login-form-btn-signin-error"
                style={{ color: "#10B981" }}
              >
                {successMessage}
              </FormHelperText>
            </FormControl>
          )}
          <SSOForm />
        </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
