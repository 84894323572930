import Link from "@mui/material/Link";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./ErrorPage.scss";

enum ERROR_STATUS {
  DATA,
  INTERNAL,
  SORRY,
}

export default function ErrorPage() {
  const params = useParams();
  const [status, setStatus] = useState(ERROR_STATUS.SORRY);
  useEffect(() => {
    if (params.status) {
      switch (params.status) {
        case "500":
          setStatus(ERROR_STATUS.INTERNAL);
          break;
        case "data":
          setStatus(ERROR_STATUS.DATA);
          break;
        default:
          setStatus(ERROR_STATUS.SORRY);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="error-page">
      <img
        src={require(`assets/${
          status === ERROR_STATUS.INTERNAL
            ? "error_500"
            : status === ERROR_STATUS.DATA
            ? "error_no_data"
            : "error_sorry"
        }.png`)}
        alt="Error"
      />
      <h1>
        {status === ERROR_STATUS.INTERNAL
          ? "Well, this is unexpected !"
          : status === ERROR_STATUS.DATA
          ? "We Are Sorry !"
          : "Oh no !"}
      </h1>
      <h5>
        {status === ERROR_STATUS.INTERNAL
          ? "We’re working to fix this problem w’ll be up and running shortly !"
          : status === ERROR_STATUS.DATA
          ? "No Data for the moment. Try to refresh or come back later"
          : "Something went wrong. Try to refresh or come back later"}
      </h5>
      <Link className="back-home" href={`/`}>
        Back home.
      </Link>
    </div>
  );
}
