import { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import MainLayout from "./layouts";
import NavbarOnlyLayout from "layouts/NavbarOnlyLayout/NavbarOnlyLayout";
import PricingPage from "views/PricingPage";

const IndexPage = lazy(() => import("./views"));
const ErrorPage = lazy(() => import("./views/ErrorPage"));
const ErrorLoginPage = lazy(() => import("./views/ErrorLoginPage"));
const ContactPage = lazy(() => import("./views/ContactPage"));
const LabsPage = lazy(() => import("./views/LabsPage"));
const SingleLabPage = lazy(() => import("./views/SingleLabPage"));
const LabInstancePage = lazy(() => import("./views/LabInstancePage"));
const MoreFromInstructorPage = lazy(
  () => import("./views/MoreFromInstructorPage")
);
const EmbeddedCommunityPage = lazy(
  () => import("./views/EmbeddedCommunityPage")
);
const ProfilePage = lazy(() => import("./views/ProfilePage"));
const PersonalDetailsPage = lazy(
  () => import("./views/ProfilePage/components/PersonalDetailsPage")
);

const MessagesPage = lazy(
  () => import("./views/ProfilePage/components/MessagesPage")
);
const FavoriteLabsPage = lazy(
  () => import("./views/ProfilePage/components/FavoriteLabsPage")
);
const ProfileSchedulePage = lazy(
  () => import("./views/ProfilePage/components/ProfileSchedulePage")
);
const SettingsPage = lazy(
  () => import("./views/ProfilePage/components/SettingsPage")
);
const LoginPage = lazy(() => import("./views/LoginPage"));
const Error404Page = lazy(() => import("./views/Error404Page"));

const renderRoutes = () => (
  <Routes>
    <Route path="/" element={<MainLayout />}>
      <Route index element={<IndexPage />} />
      <Route path="contact" element={<ContactPage />} />
      <Route path="pricing" element={<PricingPage />} />
      <Route path="labs" element={<LabsPage />} />
      <Route path="lab/:slug" element={<SingleLabPage />} />
      <Route path="instance/:id" element={<LabInstancePage />} />
      <Route path="instructor/:id" element={<MoreFromInstructorPage />} />
      <Route path="profile" element={<ProfilePage />}>
        <Route path="personal-details" element={<PersonalDetailsPage />} />
        <Route path="favorite-labs" element={<FavoriteLabsPage />} />
        <Route path="schedule" element={<ProfileSchedulePage />} />
        <Route path="messages" element={<MessagesPage />} />
        <Route path="settings" element={<SettingsPage />} />
      </Route>
      <Route path="community" element={<EmbeddedCommunityPage />} />
      <Route path="error-404" element={<Error404Page />} />
      <Route path="error-login" element={<ErrorLoginPage />} />
      <Route path="error-login/:status" element={<ErrorLoginPage />} />
    </Route>
    <Route path="/" element={<NavbarOnlyLayout />} >
      <></>
    </Route>
    <Route path="error" element={<ErrorPage />} />
    <Route path="error/:status" element={<ErrorPage />} />
    <Route path="oauth2" element={<LoginPage />} />
    <Route path="*" element={<Navigate to="/error-404" replace />} />
    
  </Routes>
);

export default renderRoutes;
