import * as yup from "yup";

export const isImageValid = (img: string): boolean => {
  return img !== null && img !== undefined && img !== "" && img !== "0";
};

export const enum SORTING_ORDER {
  ASC = "asc",
  DESC = "desc",
}

export const uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c: any) =>
    (
      ((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >>
      (c / 4)
    ).toString(16),
  );
};

export const sortDate = (arr, order) => {
  if (order === SORTING_ORDER.ASC) {
    return arr.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });
  }
  return arr.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });
};

export const fetchQCMSetFromResources = (resources) => {
  let qcmSet = {};
  resources.forEach((resource) => {
    if (resource.type === "qcm") {
      qcmSet[resource.id] = resource.value;
    }
  });
  return qcmSet;
};

/**
 * QCM Utils
 */

interface FormData {
  value: any;
  helperText: string;
  error: boolean;
  qid?: string;
}

export const initFormDataStateFromGoals = (goals: any) =>
  goals.map((goal) =>
    goal.value.map((t) => ({
      value: "",
      qid: t.id,
      error: false,
      helperText: "",
    })),
  );

export const handleQCMRadioChange = (
  event: any,
  goal_i: number,
  task_i: number,
  formData: FormData[][],
  setFormData: (data: FormData[][]) => void,
) => {
  const { value } = event.target;
  const newFormData = formData.map((goal, index) =>
    index === goal_i
      ? goal.map((task, taskIndex) =>
          taskIndex === task_i
            ? { ...task, value, helperText: " ", error: false }
            : task,
        )
      : goal,
  );
  setFormData(newFormData);
};

export const handleQCMCheckboxChange = (
  event: any,
  goal_i: number,
  task_i: number,
  answer: string,
  formData: FormData[][],
  setFormData: (data: FormData[][]) => void,
) => {
  const { checked } = event.target;
  const newFormData = formData.map((goal, index) =>
    index === goal_i
      ? goal.map((task, taskIndex) =>
          taskIndex === task_i
            ? {
                ...task,
                value: checked
                  ? [...(task.value as string[]), answer]
                  : (task.value as string[]).filter(
                      (value: string) => value !== answer,
                    ),
                helperText: " ",
                error: false,
              }
            : task,
        )
      : goal,
  );
  setFormData(newFormData);
};

export const handleQCMTextChange = (
  event: any,
  goal_i: number,
  task_i: number,
  formData: FormData[][],
  setFormData: (data: FormData[][]) => void,
) => {
  const { value } = event.target;
  const newFormData = formData.map((goal, index) =>
    index === goal_i
      ? goal.map((task, taskIndex) =>
          taskIndex === task_i
            ? { ...task, value, helperText: " ", error: false }
            : task,
        )
      : goal,
  );
  setFormData(newFormData);
};

export const handleSubmit = async (
  event: any,
  goal_i: number,
  formData: FormData[][],
  setFormData: (data: FormData[][]) => void,
  goals: any[],
  setLoadingStates: (states: boolean[]) => void,
  loadingStates: boolean[],
  instance: any,
  APISubmitQCMResponse: (url: string, data: any, access_token: string) => Promise<any>,
) => {
  event.preventDefault();
  // validate before submitting
  if (loadingStates[goal_i]) return;

  const toggleLoading = (goal_i: number, value: boolean) => {
    const newStates = [...loadingStates];
    newStates[goal_i] = value;
    setLoadingStates(newStates);
  };

  // reset all errors
  const newFormData = [...formData];
  newFormData[goal_i] = newFormData[goal_i].map((task) => ({
    ...task,
    error: false,
    helperText: "",
  }));

  const submittedFormTasks = newFormData[goal_i];
  const submittedGoalTasks = goals[goal_i];

  submittedFormTasks.forEach((task, task_i) => {
    if (!!task.qid && task.value === "") {
      console.log("Error in task", task_i, "of goal", goal_i);
      newFormData[goal_i][task_i].helperText = "Please select an option.";
      newFormData[goal_i][task_i].error = true;
    }
  });

  // If there are no errors, submit the form
  const hasErrors = submittedFormTasks.some((task) => task.error);
  if (!hasErrors) {
    toggleLoading(goal_i, true);
    const data = {
      answers: {},
      instance_id: instance.id,
      lab_name: instance.lab?.slug,
      user_id: instance.owner_id,
    };
    let SUBMIT_URL;
    submittedFormTasks.forEach((task, task_i) => {
      if (submittedGoalTasks.value[task_i].type !== "qcm") return;

      data.answers[task.qid] = task.value;
      SUBMIT_URL = `${process.env.REACT_APP_FEEDBACK_URL}/qcm/${instance.id}`;
    });
    console.log("submitting to", SUBMIT_URL, "with data", data);
    APISubmitQCMResponse(SUBMIT_URL, data, instance._access_token)
      .then((res) => {
        console.log("QCM submit successfull", res);
      })
      .catch((error) => {
        console.log("Error submitting QCM", error);
        toggleLoading(goal_i, false);
        submittedFormTasks.forEach((task, task_i) => {
          if (!!task.qid) {
            newFormData[goal_i][task_i].helperText =
              "Error submitting answer. Please try again.";
            newFormData[goal_i][task_i].error = true;
          }
        });
      })
      .finally(() => {
        // setting the form data needs to be called once.
        // since all state updates are executed at the end of the event cycle
        setFormData(newFormData);
      });
  } else {
    setFormData(newFormData);
  }
};

export const handleEventSubmit = async (
  event: any,
  goal_i: number,
  task_i: number,
  event_number: number,
  formData: FormData[][],
  setFormData: (data: FormData[][]) => void,
  setLoadingStates: (states: boolean[]) => void,
  loadingStates: boolean[],
  instance: any,
  APISubmitInstanceEvent: (url: string, data: any, access_token: string) => Promise<any>,
) => {
  event.preventDefault();
  // validate before submitting
  if (loadingStates[goal_i]) return;

  const toggleLoading = (goal_i: number, value: boolean) => {
    const newStates = [...loadingStates];
    newStates[goal_i] = value;
    setLoadingStates(newStates);
  };

  // reset all errors
  const newFormData = [...formData];
  newFormData[goal_i] = newFormData[goal_i].map((task) => ({
    ...task,
    error: false,
    helperText: "",
  }));

  // submit the event
  toggleLoading(goal_i, true);
  const data = {
    event_number: event_number,
    instance_id: instance.id,
    lab: instance.lab?.slug,
    user_id: instance.owner_id,
  };
  let SUBMIT_URL = `${process.env.REACT_APP_EVENTS_URL}`;
  console.log("submitting to", SUBMIT_URL, "with data", data);
  APISubmitInstanceEvent(SUBMIT_URL, data, instance._access_token)
    .then((res) => {
      console.log("Event submit successfull. Please refresh.", res);
      toggleLoading(goal_i, false);
      newFormData[goal_i][task_i].helperText =
        "Event submit successfully.";
      newFormData[goal_i][task_i].error = false;
    })
    .catch((error) => {
      console.log("Error submitting Event", error);
      toggleLoading(goal_i, false);
      newFormData[goal_i][task_i].helperText =
        "Error while sending event to the server. Please try again.";
      newFormData[goal_i][task_i].error = true;
    })
    .finally(() => {
      setFormData(newFormData);
    });
}


export const goalHasQcmTask = (goal) => {
  return goal.value.some((task) => task.type === "qcm");
}

/**
 * OnBoarding Utils
 */
export const mapFormikOnboardingValuesToApiAnswerRequestSchema = (
  formik_values,
) => {
  const answers = [];
  for (const key in formik_values) {
    if (typeof formik_values[key] === "string") {
      answers.push({
        answers: [formik_values[key]],
        id: key,
      });
    } else {
      answers.push({
        answers: formik_values[key],
        id: key,
      });
    }
  }
  return answers;
};

export const initialsFromName = (name: string) => {
  if (!name) return "";
  if (name.split(" ").length === 1 || name.split(" ")[1][0] === undefined)
    return `${name[0]}${name[1]}`.toUpperCase();
  return `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`.toUpperCase();
};

export const emailValidationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email address")
    .required("Email address is required"),
});
export const passwordSchema = yup // COGNITO Password requirements
  .string()
  .min(8, "Password should be of minimum 8 characters length")
  .matches(/[0-9]/, "Password must contain at least one number")
  .matches(/[a-z]/, "Password must contain at least one lowercase letter")
  .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
  .required("Password is required");
export const loginValidationSchema = emailValidationSchema.shape({
  password: passwordSchema,
});
export const signupValidationSchema = loginValidationSchema.shape({
  given_name: yup.string().required("First name is required"),
  family_name: yup.string().required("Last name is required"),
  tos: yup.bool().oneOf([true], "You must accept the terms and conditions"),
});
export const verifyValidationSchema = emailValidationSchema.shape({
  code: yup
    .string()
    .matches(/^\d{6}$/, "Verification code must have exactly 6 numbers")
    .required("Verification code is required"),
});
export const resetPasswordValidationSchema = emailValidationSchema.shape({
  code: yup
    .string()
    .matches(/^\d{6}$/, "Verification code must have exactly 6 numbers")
    .required("Verification code is required"),
  password: passwordSchema,
  confirmPassword: passwordSchema.oneOf(
    [yup.ref("password"), null],
    "Passwords must match",
  ),
});

/**
 * Hubspot Utils
 */
export const refreshChatWidget = (email, token) => {
  let w = window as any;
  if (w.HubSpotConversations && w.HubSpotConversations.widget) {
    console.log("Reloading chat bubble with email", email);
    w.hsConversationsSettings = {
      identificationEmail: email,
      identificationToken: token,
    };
    w.HubSpotConversations.clear({ resetWidget: true });
    w.HubSpotConversations.widget.load();
  }
};

export const refreshVisitorChatWidget = () => {
  let w = window as any;
  if (w.HubSpotConversations && w.HubSpotConversations.widget) {
    console.log("Reloading chat bubble for visitor");
    delete w.hsConversationsSettings.identificationEmail;
    delete w.hsConversationsSettings.identificationToken;
    w.HubSpotConversations.clear({ resetWidget: true });
    w.HubSpotConversations.widget.load();
  }
};
