import config from "utils/config";
import "./Footer.scss";
import FooterSocials from "./FooterSocials";
import { Link } from "@mui/material";

const Footer = () => {
  return (
    <section className="footer flex flex-wrap">
      <div className="content flex flex-wrap">
        <div className="signature">
          <div className="logo">
            <img src={require("assets/logo.svg").default} alt="logo" />
          </div>
          <pre className="desc">{config.footer.description}</pre>
        </div>
        <div className="navigation flex flex-wrap">
          <div className="footer_column">
            <div className="title">Company</div>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/about-us`}
              className="item emphasized"
            >
              About us
            </Link>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/careers`}
              className="item emphasized"
            >
              Careers
            </Link>
            <Link
              href={`/pricing`}
              className="item emphasized"
            >
              Pricing
            </Link>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/privacy`}
              className="item emphasized"
            >
              Privacy
            </Link>
          </div>
          <div className="footer_column">
            <div className="title">Contact</div>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/blog`}
              className="item"
            >
              Blog
            </Link>
            <Link href="/community" className="item">
              Community
            </Link>
            <Link
              onClick={(e) => {
                e.preventDefault();
                let w = window as any;
                if (w.HubSpotConversations && w.HubSpotConversations.widget) {
                  (window as any).HubSpotConversations.widget.open();
                }
              }}
              href="#"
              className="item"
            >
              Contact
            </Link>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/faq`}
              className="item"
            >
              FAQ
            </Link>
          </div>
          <div className="footer_column">
            <div className="title">More</div>
            <Link href="/?login" className="item">
              Join us
            </Link>
            <Link
              href={`${process.env.REACT_APP_LANDING_URL}/tos`}
              className="item"
            >
              Terms of use
            </Link>
            <Link
              href="https://help.scalyz.com/en/kb"
              target="_blank"
              rel="noreferrer"
              className="item"
            >
              Help center
            </Link>
          </div>
        </div>
        <FooterSocials socials={config.footer.socials}></FooterSocials>
      </div>

      <div className="copyright">
        {config.footer.copyright}
        <br />
        {config.footer.build_version}
        {process.env.REACT_APP_BUILD_VERSION}
      </div>
    </section>
  );
};

export default Footer;
