import { InfoOutlined } from "@mui/icons-material";
import { Popover, Typography } from "@mui/material";
import { createContext, useContext, useState } from "react";

const DebugContext = createContext({
  debugMode: false,
  toggleDebugMode: () => {},
});

let DebugProvider;
let useDebugMode;

if (process.env.REACT_APP_BUILD_ENV === "true") {
  DebugProvider = ({ children }) => {
    const [debugMode, setDebugMode] = useState(
      process.env.REACT_APP_BUILD_ENV === "true"
    );

    const toggleDebugMode = () => {
      setDebugMode((prevMode) => !prevMode);
    };
    /**
     * Debug details popover
     */
    const [anchorPopover, setAnchorPopover] = useState(null);

    const handleOpenPopover = (event) => {
      setAnchorPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
      setAnchorPopover(null);
    };
    const open = Boolean(anchorPopover);
    const id = open ? "details-popover" : undefined;

    return (
      <DebugContext.Provider value={{ debugMode, toggleDebugMode }}>
        <div className="fixed flex flex-row gap-1 bottom-4 left-4 z-[9999]">
          <button
            onClick={toggleDebugMode}
            className="bg-blue hover:bg-blue-dark text-white py-2 px-4 rounded-full shadow-lg transition duration-150 ease-in-out"
          >
            Debug - ({debugMode ? "ON" : "OFF"})
          </button>
          {debugMode && (
            <div className="content-center">
              <button
                aria-describedby={id}
                onClick={handleOpenPopover}
                className="bg-blue hover:bg-blue-dark text-white p-1 rounded-full shadow-lg transition duration-150 ease-in-out"
              >
                <InfoOutlined />
              </button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorPopover}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{
                  "& .MuiPaper-root": {
                    borderRadius: "8px",
                    boxShadow:
                      "0px 7px 29px 0px rgba(100, 100, 111, 0.20)!important",
                  },
                }}
              >
                <Typography sx={{ p: 2 }}>
                  <strong>Track ID:</strong>{" "}
                  {localStorage.getItem(process.env.REACT_APP_TRACK_ID_LOCAL_STORAGE_KEY)}
                </Typography>
              </Popover>
            </div>
          )}
        </div>
        {children}
      </DebugContext.Provider>
    );
  };

  useDebugMode = () => useContext(DebugContext);
} else {
  // Placeholder components for production
  DebugProvider = ({ children }) => <>{children}</>;
  useDebugMode = () => ({ debugMode: false, toggleDebugMode: () => {} });
}

export { DebugProvider, useDebugMode };
