import "./_index.scss";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Link,
  TextField,
} from "@mui/material";
import LoadingImage from "components/LoadingImage";
import config from "utils/config";

const ResetPasswordForm = (props) => {
  const {
    formik,
    submitError,
    setSubmitError,
    handleSwitchToLogin,
    successMessage,
  } = props;

  return (
    <>
      <div className="auth-header">
        <img
          src={require("assets/login_illustration.svg").default}
          alt="logo"
          className="auth-header-illustration"
          style={{
            height: config.auth_popup.login_modal.header.illustrationHeight,
          }}
        />
        <div className="auth-header-title">
          {config.auth_popup.login_modal.header.title}
        </div>
        <p className="auth-header-subtitle">
          {config.auth_popup.forget_password.header_subtitle}
        </p>
      </div>
      <div className="auth-form-container">
        <div
          style={{
            height: "fit-content",
          }}
          className="auth-form"
        >
          <div className="auth-form-forgot-password">
            <Grid container>
              <Grid item xs={12}>
                <p className="auth-form-forgot-password-title">
                  {config.auth_popup.reset_password.title}
                </p>
              </Grid>
              <Grid item xs={12}>
                <p className="auth-form-forgot-password-subtitle">
                  {config.auth_popup.reset_password.subtitle}
                </p>
              </Grid>
              <Grid item xs={12} style={{ margin: "24px 0 0 0" }}>
                <TextField
                  fullWidth
                  id="code"
                  name="code"
                  label="Verification Code"
                  InputProps={{ sx: { borderRadius: "8px" } }}
                  value={formik.values.code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.code && Boolean(formik.errors.code)}
                  helperText={formik.touched.code && formik.errors.code}
                />
              </Grid>
              <Grid item xs={12} style={{ margin: "24px 0" }}>
                <TextField
                  fullWidth
                  name="password"
                  label="Create new password"
                  type={"text"}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{ sx: { borderRadius: "8px" } }}
                />
              </Grid>
              <Grid item xs={12} style={{ margin: "0 0 24px 0" }}>
                <TextField
                  fullWidth
                  name="confirmPassword"
                  label="Confirm new password"
                  type={"text"}
                  InputProps={{ sx: { borderRadius: "8px" } }}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                />
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                {formik.isSubmitting ? (
                  <LoadingImage></LoadingImage>
                ) : (
                  <FormControl fullWidth>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setSubmitError("");
                        formik.handleSubmit();
                      }}
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      disableTouchRipple
                      className="auth-form-confirm-btn hover:!bg-blue-dark"
                      variant="contained"
                    >
                      Confirm new password
                    </Button>
                    <FormHelperText>{submitError}</FormHelperText>
                    <FormHelperText style={{ color: "#10B981" }}>
                      {successMessage}
                    </FormHelperText>
                  </FormControl>
                )}
              </Grid>
              <Grid item xs={12}>
                <p className="auth-form-forgot-password-go-to-signup">
                  Just remember?{" "}
                  <Link onClick={handleSwitchToLogin}>Sign In</Link>
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
