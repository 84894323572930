import "styles/global.scss";
import "../../i18n";

import Header from "../components/Header";

import "./NavbarOnlyLayout.scss";
import { Outlet } from "react-router-dom";

const NavbarOnlyLayout = (props: {children?}) => {
  return (
    <div className="navbar-only-layout">
      <div className="container">
        <Header />
        <Outlet />
      </div>
    </div>
  );
};

export default NavbarOnlyLayout;
