import { Popover } from "@mui/material";
import { cn } from "utils/cn";

const NotificationItem = ({ icon, title, description, time, className }) => (
  <>
    <div className={cn(`flex gap-4 items-start`, className)}>
      <div className="flex flex-col">
        <div className="flex justify-center items-center px-2.5 bg-blue h-[33px] rounded-[40px] w-[33px]">
          <img src={icon} alt="" className="w-full aspect-square" />
        </div>
      </div>
      <div className="flex flex-col w-full">
        <div className="tracking-normal custom-text-poppins-400-14px text-[#111827]">
          {title}
        </div>
        <div className="flex justify-between gap-4 text-xs">
          <div className="grow custom-text-poppins-300-10px text-[#111827]">
            {description}
          </div>
        </div>
      </div>
      <div className="custom-text-poppins-500-10px text-[#AAA] self-end -mb-[7px]">
        {time}
      </div>
    </div>
  </>
);

const NotificationsPopup = (props) => {
  const { id, open, anchorEl, handleClose } = props;
  const notifications = [
    {
      icon: require("assets/sms.svg").default,
      title: "Email sent",
      description: "Check your inbox or spam folders",
      time: "1h",
    },
    {
      icon: require("assets/user-edit.svg").default,
      title: "Profile info changes",
      description: "Your name was changed.....",
      time: "2d",
    },
    {
      icon: require("assets/calendar-white.svg").default,
      title: "Lab booked",
      description: "Check your profile",
      time: "4h",
    },
    {
      icon: require("assets/sms.svg").default,
      title: "Email sent!!",
      description: "Check your inbox or spam folders",
      time: "4h",
    },
    {
      icon: require("assets/timer.svg").default,
      title: "30min left, Hurry up!!",
      description: "you can do it ..",
      time: "5h",
    },
  ];

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      slotProps={{
        paper: {
          sx: {
            borderRadius: "20px",
            boxShadow: "0px 7px 29px rgba(100, 100, 111, 0.2)",
            maxWidth: "283px",
            maxHeight: "328px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          },
        },
      }}
    >
      <h2 className="custom-text-poppins-600-14px text-[#202020] ml-[25px] mt-[19px]">
        Notifications
      </h2>
      <div className="flex flex-col gap-5 mt-[26px] px-[19px] mb-[11px]  overflow-y-scroll custom-scrollbar">
        {notifications.map((notification, index) => (
          <NotificationItem
            className={`px-1 pb-[7.5px] border-b-solid border-b-[1px] border-b-[rgba(0,0,0,0.2)]`}
            key={index}
            {...notification}
          />
        ))}
      </div>
    </Popover>
  );
};

export default NotificationsPopup;
