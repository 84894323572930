import TrapFocus from "@mui/material/Unstable_TrapFocus";

import "./_index.scss";
import {
  Fade,
  Paper,
  Stack,
  Box,
  Typography,
  Button,
  Link,
} from "@mui/material";

const CookiePolicyModal = ({ id, open, handleClose }) => {
  return (
    <TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={true} in={open}>
        <Paper
          role="dialog"
          aria-modal="false"
          aria-label="Cookie banner"
          square
          className="cookie-banner"
          variant="outlined"
          tabIndex={-1}
          sx={{
            position: "fixed",
            bottom: { xs: "10px", sm: "50px" },
            left: { xs: "10px", sm: "10px" },
            right: { xs: "10px", sm: "auto" },
            maxWidth: { xs: "calc(100% - 20px)", sm: "500px" },
            m: 0,
            p: { xs: "20px 23px", sm: "52px 56px" },
            borderRadius: "20px",
            zIndex: 9999,
            boxShadow:
              "0px 14px 42px 0px rgba(8, 15, 52, 0.06), 0px 7px 29px 0px rgba(100, 100, 111, 0.20)",
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            gap={2}
            textAlign="center"
          >
            <Box sx={{ alignSelf: "center", maxWidth: {xs: "50px", sm: "100%"} }}>
              <img
                src={require("assets/cookie-banner.svg").default}
                alt="cookie"
              />
            </Box>
            <Stack gap={"14px"} direction="column" className="cookie-text">
              <Typography variant="h5" className="title">
                Have a cookie :)
              </Typography>
              <Typography variant="body1" className="content">
                Our website use cookies. By continuing, we assume your
                permission to deploy cookies as detailed in our{" "}
                <Link href={`${process.env.REACT_APP_LANDING_URL}/privacy`}>
                  Privacy Policy
                </Link>
                .
              </Typography>
            </Stack>
            <Stack width="100%" direction="column" gap={"5px"} sx={{width: {xs: "70%", sm: "100%"}, height: {xs: "50px", sm: "auto"}}}>
              <Button
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
                onClick={handleClose}
                variant="contained"
                className="hover:!bg-blue-dark"
              >
                Got it
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Fade>
    </TrapFocus>
  );
};

export default CookiePolicyModal;
