const DEFAULT = {
  cropper: {
    crop: { x: 0, y: 0 },
    zoom: 1,
    size: 335,
  },
  instance_page: {
    congratulate: {
      title: "Congratulation!",
      subtitle: "You've done a great job 🎉",
    },
    quit: {
      title: "Your progress is valuable",
      subtitle: "We look forward to your return 🎉",
    },
  },
  app_home: {
    provides: [
      {
        title: "Multiple Categories",
        icon: require("assets/home-provides-categories.svg").default,
        desc: "Explore diverse topics with our wide range of labs, tailored to various interests and skill levels.",
      },
      {
        title: "Best Labs",
        icon: require("assets/home-provides-best-labs.svg").default,
        desc: "Elevate your learning with our top-tier labs, designed for maximum value and expertise.",
      },
      {
        title: "Flexible Calendar",
        icon: require("assets/home-provides-calendar.svg").default,
        desc: "Take Control of your learning pace with our flexible scheduling options.",
      },
      {
        title: "Customization",
        icon: require("assets/home-provides-custom.svg").default,
        desc: "Personalize your training to fit your unique goals with our customizable lab offerings.",
      },
    ],
  },
  auth_popup: {
    login_modal: {
      header: {
        illustrationHeight: "245px",
        title: "We are Happy\nto see you again!",
        subtitle:
          "Start improving your skills faster with \nsimplified and straight to the point labs created for you.",
      },
      form: {
        title: "Welcome Back,\nSign In To Continue",
        tos: "By Signing in, I agree that I have read and accepted the Terms of Use and Privacy Policy.",
      },
    },
    signup_modal: {
      header: {
        illustrationHeight: "200px",
        title: "Get Your FREE\n3-Month Trial Now!",
        subtitle:
          "& Start improving your skills faster with simplified\nand straight to the point Labs Created for you.",
        content: [
          {
            icon: require("assets/monetization_on_black_24dp.svg").default,
            title: "Absolutely FREE",
            description: "No hidden charges, No credit card required",
          },
          {
            icon: require("assets/dynamic_form_black_24dp.svg").default,
            title: "Fast & Easy",
            description: "Sign up and get access instantly",
          },
          {
            icon: require("assets/ic_assignment_ind.svg").default,
            title: "Your Own Data",
            description: "Set your prefrences and enjoy your free trial",
          },
          {
            icon: require("assets/ic_stars.svg").default,
            title: "Unlimited Features",
            description: "Get Access to all features!",
          },
        ],
      },
      form: {
        title: "Welcome To Scalyz,\nSign Up To Continue",
        tos: "By signing up, I agree that I have read and accepted the Terms of Use and Privacy Policy.",
      },
    },
    verify: {
      title: "Please verify you account",
      subtitle:
        "Enter the digit code we sent to your email\naddress to verify your new account.",
    },
    forget_password: {
      header_subtitle:
        "Continue to improve your skills faster with simplified\nand straight to the point Labs Created for you.",
      title: "Forgot password?",
      subtitle:
        "No worries! Just enter your email and we’ll send\nyou a reset password link.",
    },
    reset_password: {
      title: "New Password",
      subtitle:
        "Please create a new password that you don’t\nuse on any other site.",
    },
    onboarding: {
      left_side: {
        illustrationHeight: "222px",
        title: "Let’s customize Your\nLearning Journey! ✨️",
      },
      right_side: {},
    },
    congratulate: {
      title: "Congratulation!",
      subtitle: "Email successfully verified.",
    },
  },
  footer: {
    name: "Scalyz",
    description:
      "Improve your skills faster & easier\nwhile working on our best Labs",
    socials: [
      {
        type: "linkedin",
        name: "LinkedIn",
        link: "https://www.linkedin.com/company/scalyzcompany/",
        shouldOpenInNewTab: true,
      },
      {
        type: "community",
        name: "Community",
        link: `/community`,
        shouldOpenInNewTab: false,
      },
    ],
    getSocialObjectFromType: (name) =>
      DEFAULT.footer.socials.find(
        (social) => social.name.toLowerCase() === name.toLowerCase()
      ),
    mapSocialIconToImage: {
      meta: require("assets/Facebook Icon.svg").default,
      linkedin: require("assets/Linkedin Icon.svg").default,
      twitter: require("assets/Twitter Icon.svg").default,
      community: require("assets/community.svg").default,
    },
    copyright: "Copyright © 2024 Scalyz",
    build_version: "build version: ",
  },
};
export default DEFAULT;
